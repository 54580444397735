<!--
 * @Descripttion: 
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-06-24 08:54:09
 * @LastEditors: alex.yang
 * @LastEditTime: 2022-07-08 17:46:46
-->
<template>
    <div class="userFeedback">
        <div class="title-wrap">
          <div class="tag"></div>
          <div class="title">反馈列表</div>
        </div>
        <div class="case-wrap">
          <commonSelect
              @changeDate="changeDate"
              @clickTagDate="clickTagDate"
          ></commonSelect>
        </div>
        <div class="case-wrap">
            <div class="title-wraps">
              <div style="margin-right: 20px">
                <el-select clearable v-model="state" @change="selectstateChange" placeholder="请选择反馈类型">
                  <el-option
                      v-for="item in feedbackTions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </div>
              <div class="select-wrap">
                <el-select clearable v-model="epId" @change="selectChange" placeholder="请选择品牌筛选">
                  <el-option
                      v-for="item in epConditions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </div>
              <div class="search-wraps">
                <el-input clearable v-model="keyword" placeholder="请输入搜索内容"></el-input>
                <el-button class="btns" @click="clickSearch" type="primary">搜索</el-button>
              </div>
            </div>
            <div class="table-wrap" v-loading="loading">
                <el-table :data="tableData" border style="width: 100%">
                    <el-table-column align="center" prop="id" label="ID" width="80"></el-table-column>
                    <el-table-column align="center" prop="enterprise_brand.name" label="品牌名称" ></el-table-column>
                    <el-table-column align="center" prop="shop.name" label="门店名称" ></el-table-column>
                    <el-table-column align="center" prop="user.username" label="反馈人" ></el-table-column>
                    <el-table-column align="center" prop="user.mobile" label="联系号码" ></el-table-column>
                    <el-table-column align="center" prop="email" label="反馈类型" >
                        <template slot-scope="scope">
                            <div v-if="scope.row.type == 3">功能问题</div>
                            <div v-if="scope.row.type == 4">意见提议</div>
                            <div v-if="scope.row.type == 5">其他反馈</div>
                            <div v-if="scope.row.type == 6">图文内容</div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="content" label="问题和建议"></el-table-column>
                    <el-table-column align="center" label="截图" width="80">
                        <template slot-scope="scope">
                            <img class="image" @click="clickImage(scope.row)" v-if="scope.row.imgs" :src="scope.row.imgs[0]" />
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="last_login_ip" label="状态" width="100">
                        <template slot-scope="scope">
                            <div v-if="scope.row.state == 0">未处理</div>
                            <div v-if="scope.row.state == 1">已处理</div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" :show-overflow-tooltip="true" prop="create_timex" label="创建时间" ></el-table-column>
                    <el-table-column align="center" prop="system_admin.nickname" label="处理人" ></el-table-column>
                    <el-table-column align="center" label="操作" width="90">
                        <template slot-scope="scope">
                            <div class="operation-wrap">
                                <el-tooltip class="item" effect="dark" content="确认处理" placement="top">
                                    <i v-if="scope.row.state == 0" @click="confirmHandle(scope.row)" class="iconfont el-icon-s-check"></i>
                                </el-tooltip>
                                <el-tooltip class="item" effect="dark" content="删除" placement="top">
                                    <i @click="clickDelete(scope.row)" class="iconfont el-icon-delete"></i>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="pagination-wrap">
                    <el-pagination
                        @current-change="handleCurrentChange"
                        :current-page.sync="currentPage"
                        :page-size="10"
                        layout="total, prev, pager, next"
                        :total="total">
                    </el-pagination>
                </div>
            </div>
        </div>
        <div v-if="isShowImages">
            <el-image-viewer 
            :on-close="closeViewer" 
            :url-list="imageList" />
        </div>
    </div>
</template>

<script>
import pageMixin from '@/internal/pageMixin'
import common from "@/utils/common.js"
import commonSelect from "@/components/commonSelect.vue"
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
export default {
    mixins:[pageMixin],
    components: {
      ElImageViewer,
      commonSelect
    },
    data () {
        return {
            pageMixin_index:'userFeedback',
            tableData: [],
            keyword: '',
            epId: '',
            dateType: '',
            startDate: '',
            endDate: '',
            currentPage: 1,
            total: 0,
            loading: false,
            isShowImages: false,
            state: '',
            imageList: [],
            epConditions: [],
            feedbackTions: [{
              value: 0,
              label: '未处理'
            },{
              value: 1,
              label: '已处理'
            }]
        }
    },
    mounted () {
        this.getFeedbackIndex()
        this.getEpConditions();
    },
    methods: {
        // 日期选择
        changeDate(startDate,endDate){
          this.currentPage = 1;
          this.startDate = startDate.slice(0,11)+' 00:00:01';
          this.endDate = endDate.slice(0,11)+' 23:59:59';
          this.getFeedbackIndex()
        },
        clickTagDate(item){
          this.dateType = item.label;
          this.currentPage = 1;
          if(item.label != 'date'){
            this.startDate = '';
            this.endDate = '';
            this.getFeedbackIndex();
          }
        },
        // 搜索
        clickSearch(){
          this.currentPage = 1;
          this.getFeedbackIndex()
        },
        selectstateChange(){
          this.currentPage = 1;
          this.getFeedbackIndex();
        },
        // 更据用户筛选
        selectChange(){
          this.currentPage = 1;
          this.getFeedbackIndex();
        },
        // 获取品牌列表
        getEpConditions(){
          common.connect("/customerservicev1/enterprise_brand/EpConditions",{},(res)=>{
            let resultSecond =  res.data.map(v =>{
              return{
                value: v.ep_id,
                label: v.name
              }
            })
            this.epConditions = resultSecond;
          });
        },
        // 获取列表数据
        getFeedbackIndex(){
            let params = {
                keyword: this.keyword,
                ep_id: this.epId,
                date_type: this.dateType,
                start_date: this.startDate,
                end_date: this.endDate,
                page: this.currentPage,
                state: this.state
            }
            this.loading = true;
            common.connect("/customerservicev1/feedback/index",params,(res)=>{
                this.total = res.data.count;
                res.data.list.forEach(v => {
                    v.imgs = JSON.parse(v.imgs)
                });
                this.tableData = res.data.list;
                this.loading = false;
            });
        },
        clickImage(row){
            this.isShowImages = true;
            this.imageList = row.imgs;
        },
        // 关闭查看器
        closeViewer() {
            this.isShowImages = false;
            this.imageList = [];
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getFeedbackIndex();
        },
      // 删除
      clickDelete(row){
        this.$confirm('此操作将永久删除该反馈, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          common.connect("/customerservicev1/feedback/delete",{id: row.id},(res)=>{
            this.$message({
              message: res.msg,
              type: 'success'
            });
            this.getFeedbackIndex();
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      // 确认已处理
      confirmHandle(row){
        this.$confirm('此操作将确认处理反馈, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          common.connect("/customerservicev1/feedback/confirm",{id: row.id},(res)=>{
            this.$message({
              message: res.msg,
              type: 'success'
            });
            this.getFeedbackIndex();
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          });
        });
      },
    },
}
</script>

<style lang='scss'>
.userFeedback{
    background: rgb(240, 242, 245);
    padding: 10px 25px;
    height: calc(100vh - 80px);
    overflow-y: auto;
    .title-wrap{
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin: 5px 0;
      .tag{
        width: 4px;
        height: 20px;
        background: #409EFF;
        border-radius: 10px;
      }
      .title{
        font-size: 18px;
        font-weight: bold;
        padding-left: 10px;
      }
    }
    .case-wrap{
        background: #fff;
        padding: 20px;
        margin: 20px 0;
        border-radius: 6px;
        .title-wraps{
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          padding-bottom: 15px;
          .search-wraps{
            display: flex;
            align-items: center;
            margin-left: 20px;
            .btns{
              margin-left: 10px;
            }
          }
        }
        .table-wrap{
            .image{
                width: 40px;
                cursor: pointer;
            }
            .pagination-wrap{
                display: flex;
                justify-content: flex-end;
                align-items: center;
                padding-top: 20px;
            }
            .operation-wrap{
                display: flex;
                justify-content: center;
                align-items: center;
                .iconfont{
                    font-size: 18px;
                    padding: 0 10px;
                    cursor: pointer;
                }
            }
        }
    }
}
</style>
